import React from "react"
import styled from "styled-components"
import { LocaleContextProvider } from "./src/context/localeContext"
import { FormContextProvider } from "./src/context/formContext"

export default function RootLayout({ children }) {
  return (
    <>
      <LocaleContextProvider>
        <FormContextProvider>
          <StyledRootLayout>{children}</StyledRootLayout>
        </FormContextProvider>
      </LocaleContextProvider>
    </>
  )
}

const StyledRootLayout = styled.div``
