import React, { createContext, useContext, useState } from "react"

export type Prefix = "/" | "/sv/"
export type LocaleContextType = {
  prefix: Prefix
  path: string
}

type Locales = {
  locale: LocaleContextType
  setLocale: React.Dispatch<React.SetStateAction<LocaleContextType>>
}
type DocumentProviderProps = {
  children: React.ReactNode
}

export const LocaleContext = createContext<Locales>({
  locale: { prefix: "/", path: "/" },
  setLocale: () => {},
})

export const useLocaleContext = () => {
  return useContext(LocaleContext)
}

export const LocaleContextProvider: React.FC<DocumentProviderProps> = ({
  children,
}) => {
  const [locale, setLocale] = useState<LocaleContextType>({
    prefix: "/",
    path: "",
  })

  const value = {
    locale,
    setLocale,
  }

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  )
}
