import React, { useState, createContext, useContext } from "react"

export const FormContext = createContext({
  formContext: {},
  setFormContext: () => {},
})

export const useFormContext = () => {
  return useContext(FormContext)
}

export const FormContextProvider = ({ children }) => {
  const [formActions, setFormActions] = useState({})

  const handleFormActions = (value) => {
    setFormActions(value)
  }

  const value = {
    formActions,
    handleFormActions,
  }

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>
}
